import styled, { css } from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const Container = styled.div`
  width: 60%;
  position: relative;
  top: 2px;
  display: flex;
  align-items: center;
  max-width: 50%;

  ${breakpoint("tablet")`
    max-width: 60%;
  `}

  ${(props) =>
    props.small &&
    css`
      width: 30%;
    `}

  ${(props) =>
    props.medium &&
    css`
      width: 44%;

      ${breakpoint("tablet")`
        width: 30%;
      `}
    `}

  ${(props) =>
    props.mobileOnly &&
    css`
      height: 25px;

      > img {
        height: 100%;
        width: auto;
      }
    `}

  ${(props) =>
    props.mobileOnly &&
    breakpoint("desktop")`
    display: none;
  `}

  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: 60px;
    `}


  ${breakpoint("desktop")`
    top: 0;
    max-width: 300px;
  `}
`

export const Img = styled.img`
  width: 100%;
`
