const adventurechina = {
  id: 11,
  name: "adventurechina",
  friendlyName: "Adventure China",
  tagline: "Welcome to your China Adventure.",
  apply: "Apply now for summer 2024",
  season: "Summer",
  links: {
    appBase: {
      development: "http://adventurechina.test:3000",
      staging: "https://mf-staging.adventurechina.com",
      production: "https://login.adventurechina.com"
    },
    baseWithoutProtocol: "www.adventurechina.com"
  },
  employerType: "Camp"
}

export default adventurechina
