const canago = {
  id: 4,
  name: "canago",
  friendlyName: "Canago",
  tagline: "Your Canadian Working Holiday.",
  apply: "Apply now for summer 2024",
  season: "Summer",
  links: {
    appBase: {
      development: "http://canago.test:3000",
      staging: "https://mf-staging.canago.com",
      production: "https://login.canago.com"
    },
    baseWithoutProtocol: "www.canago.com"
  },
  employerType: "Resort"
}

export default canago
