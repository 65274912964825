import React, { Component } from "react"
import BrandContext from "@context/BrandContext.js"
import { Container, Img } from "./styles"
import PropTypes from "prop-types"
class Logo extends Component {
  static contextType = BrandContext

  render() {
    const src = this.props.coloured
      ? `/assets/${this.context.name}/coloured_logo.svg`
      : `/assets/${this.context.name}/logo.svg`
    return (
      <Container {...this.props}>
        <Img src={src} alt={this.context.friendlyName} />
      </Container>
    )
  }
}

Logo.propTypes = {
  marginBottom: PropTypes.bool,
  coloured: PropTypes.bool
}

Logo.defaultProps = {
  marginBottom: false,
  coloured: false
}

export default Logo
